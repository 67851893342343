let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  BACKEND_SERVER = "https://api.nbsense.in/";
}

const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "/route",
  fontFamily: `Red Hat Display`,
  borderRadius: 6,
  API_SERVER: BACKEND_SERVER,
  // API_SERVER:"http://localhost:5001/"
  // API_SERVER:"http://192.168.0.110:5000/"
};

export default config;
